body {
  font-family: "Calling Code", sans-serif;
  display: table-cell;
  vertical-align: middle;
  padding: 0px;
  margin: 0px;
}

html {
  display: table;
  margin: auto;
  width: 100%;
}
