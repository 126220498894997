@font-face {
  font-family: "Calling Code";
  src: url("./fonts/CallingCode-Regular.otf") format("opentype"),
    url("./fonts/CallingCode-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.calling-code {
  font-family: "Calling Code", sans-serif;
}

@font-face {
  font-family: "Chlorinar";
  src: url("./fonts/CHLORINR.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.chlorinar {
  font-family: "Chlorinar", sans-serif;
}

@font-face {
  font-family: "Double Feature";
  src: url("./fonts/DoubleFeature20.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.double-feature {
  font-family: "Double Feature", sans-serif;
  -webkit-text-stroke: 1px black;
}

@font-face {
  font-family: "Permanent Marker";
  src: url("./fonts/PermanentMarker.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.permanent-marker {
  font-family: "Permanent Marker", sans-serif;
}

@font-face {
  font-family: "Planet Benson";
  src: url("./fonts/planet benson 2.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.planet-benson {
  font-family: "Planet Benson", sans-serif;
}

@font-face {
  font-family: "Pixeloid Sans";
  src: url("./fonts/PixeloidSans.otf") format("opentype"),
    url("./fonts/PixeloidSans.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.pixeloid-sans {
  font-family: "Pixeloid Sans";
}
