/* CSS */
.comic-button {
  font-family: "Calling Code", sans-serif;
  font-size: 16px;
  text-decoration: none;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-bottom: 5px;
  margin-right: 3px;
}

.comic-button:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

.comic-button.mobile {
  padding: 5px 10px;
  font-size: 8px;
}

@media (min-width: 768px) {
  .comic-button {
    padding: 0.25em 0.75em;
  }
}
