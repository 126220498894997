.sewer-scum-char-gen-container {
  max-width: 1236px;
  margin: 0 auto;
}

/* Container holding the image and the text */
.container {
  position: relative;
  text-align: left;
  background-color: white;
  width: 100%;
}

/* Background character sheet image*/
.character-sheet {
  width: inherit;
}

/* Character Name text */
.character-name-text {
  position: absolute;
  top: 18.5%;
  left: 8%;
  max-width: 27.5%;
  font-weight: bold;
  height: 3.5%;
  display: flex;
  align-items: flex-end;
  line-height: 1;
}

.character-name-text span {
  background-color: white;
}

/* Class name text */
.class-name {
  position: absolute;
  top: 52%;
  left: 11%;
  max-width: 28%;
  background-color: white;
  transform: rotate(-3deg);
}

/* Class and Abilities text */
.class-features {
  position: absolute;
  top: 69%;
  left: 5.5%;
  max-width: 29%;
  line-height: 1.05;
  white-space: pre-line;
  background-color: white;
}

/* luck die text */
.luck-die {
  position: absolute;
  top: 72%;
  left: 53.75%;
  font-weight: bold;
  background-color: white;
}

/* luck text */
.luck {
  position: absolute;
  top: 77.75%;
  left: 52.5%;
  font-weight: bold;
  background-color: white;
}

/* cash text */
.cash {
  position: absolute;
  top: 20.5%;
  left: 42%;
  font-weight: bold;
  background-color: white;
}

/* max hp text */
.hp {
  position: absolute;
  top: 13%;
  left: 61.5%;
  font-weight: bold;
  background-color: white;
}

/* weapon text */
.weapon {
  position: absolute;
  top: 7%;
  left: 72.5%;
  max-width: 18.75%;
  background-color: white;
  transform: rotate(-3deg);
}

/* strength stat text */
.strength {
  position: absolute;
  top: 31%;
  left: 61.25%;
  font-weight: bold;
  background-color: white;
}

/* agility stat text */
.agility {
  position: absolute;
  top: 39%;
  left: 57%;
  font-weight: bold;
  background-color: white;
}

/* presence stat text */
.presence {
  position: absolute;
  top: 46.75%;
  left: 61.75%;
  font-weight: bold;
  background-color: white;
}

/* toughness stat text */
.toughness {
  position: absolute;
  top: 54.25%;
  left: 57.25%;
  font-weight: bold;
  background-color: white;
}

/* knowledge stat text */
.knowledge {
  position: absolute;
  top: 62%;
  left: 61%;
  font-weight: bold;
  background-color: white;
}

/* equipment text */
.armor-text {
  position: absolute;
  top: 28%;
  left: 76%;
  max-width: 19.5%;
  line-height: 1.05;
  background-color: white;
  transform: rotate(-2deg);
}

/* equipment text */
.equipment {
  position: absolute;
  top: 57%;
  left: 69.5%;
  max-width: 30%;
  background-color: white;
}

/* terrible trait 1 text */
.character-day-jobs {
  position: absolute;
  top: 31%;
  left: 5%;
  max-width: 28%;
  background-color: white;
}

/* quirks text */
.quirks {
  position: absolute;
  top: 34%;
  left: 5%;
  max-width: 26%;
  background-color: white;
}

/* Broken body text */
.physical-distinctions {
  position: absolute;
  top: 37%;
  left: 5%;
  max-width: 26%;
  background-color: white;
}

/* bad habit text */
.obsessions {
  position: absolute;
  top: 40%;
  left: 5%;
  max-width: 26%;
  background-color: white;
}

/* troubling tale text */
.styles {
  position: absolute;
  top: 43%;
  left: 5%;
  max-width: 26%;
  background-color: white;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
